import "regenerator-runtime/runtime"
import { getUpSellProductsList } from "../domain/datasources/products.remote"

export const handleGetUpSellProductsList = (cartId, callback) => {
  async function asyncCall() {
    const response = await getUpSellProductsList(cartId)
    if (response.state === "success") {
      callback(null, response.productsList)
    }
  }
  return asyncCall()
}
