import { Http } from "../http"

const http = new Http()

export function getUserIp() {
  return new Promise(resolve => {
    http.getFromExternalAPI(`https://ipecho.net/plain`, {}, (err, res) => {
      err
        ? resolve(err)
        : resolve({
            state: "success",
            userIp: res.data,
          })
    })
  })
}
