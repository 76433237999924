import "regenerator-runtime/runtime"
import { getCart } from "../domain/datasources/carts.remote"

export const handleCartInfo = (cartId, callback) => {
  async function asyncCall() {
    const response = await getCart(cartId)
    if (response.state === "success") {
      return callback(null, response.cartInfo)
    }
    callback("Oops, we can't find your order")
  }
  return asyncCall()
}
