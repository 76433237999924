import "regenerator-runtime/runtime"
import { validateCoupon } from "../domain/datasources/promotions.remote"

export const handleValidateCoupon = (cardId, coupon, ip, callback) => {
  async function asyncCall() {
    const response = await validateCoupon(cardId, coupon, ip)

    response.state === "success"
      ? callback(null, response.message)
      : callback(response)
  }
  return asyncCall()
}
