import { Http } from "../http"

const http = new Http()

export function validateCoupon(cardId, coupon, ip) {
  return new Promise(resolve => {
    http.get(
      "promotions/coupons/validates",
      {
        cart_id: cardId,
        code: coupon,
        ip: ip,
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
              state: "success",
              message: "Coupon validated",
            })
      }
    )
  })
}
