import "regenerator-runtime/runtime"
import { createOrder } from "../domain/datasources/orders.remote"

export const handleCreateNewOrder = async (
  methodType,
  cartId,
  ip,
  utmSource,
  utmMedium,
  callback
) => {

    return await createOrder(
      methodType,
      cartId,
      ip,
      utmSource,
      utmMedium,
      callback
    )

}
