import "regenerator-runtime/runtime"
import { replaceItemFromCart } from "../domain/datasources/carts.remote"

export const handleReplaceItemFromCart = (cartId, currentItemSku, newItemSku, callback) => {
  async function asyncCall() {
    const response = await replaceItemFromCart(cartId, currentItemSku, newItemSku)
    if (response.state === "success") {
      callback(null, response.message)
    }
  }
  return asyncCall()
}