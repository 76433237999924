/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { CaretDownOutlined } from "@ant-design/icons"
import { handleGetCartIdFromCookie } from "../../../data/usecases/getcartidfromcookie"
import { handleCartInfo } from "../../../data/usecases/getcartinfo"
import { handleGetUpSellProductsList } from "../../../data/usecases/getupsellprodcutslist"
import { handleGetRelatedProductsList } from "../../../data/usecases/getrelatedproductslist"
import { handleReplaceItemFromCart } from "../../../data/usecases/replaceitemfromcart"
import { handleUpdateCart } from "../../../data/usecases/updatecart"
import { handleAddItemsToCart } from "../../../data/usecases/additemstocart"
import { handleDeleteItemFromCart } from "../../../data/usecases/deleteitemfromcart"
import { handleCreateNewOrder } from "../../../data/usecases/createneworder"
import { handleUserIp } from "../../../data/usecases/getuserip"
import { handleValidateCoupon } from "../../../data/usecases/validatecoupon"
import { handleCreateCart } from "../../../data/usecases/createnewcart"
import { getQueryParam } from "../../../data/managers/getqueryparam"
import Layout from "../../chunks/layout"
import UserInfo from "./components/userinfo"
import CheckoutInfo from "./components/checkoutinfo"
import Testimonials from "../../components/testimonials"
import { Helmet } from "react-helmet"
import { notification } from "antd"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import TrustIcons from "../../../static/img/secure.png"
import RealBoostLogo from "./components/realboostlogo"
import { currencyToSymbol } from "../../../data/managers/currencytosymbol"
import { useCookies } from "react-cookie"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement, IdealBankElement
} from "@stripe/react-stripe-js"
import { handleCreateNewTransaction } from "../../../data/usecases/createnewtransaction"
import { handleConfirmPayment } from "../../../data/usecases/confirmpayment"


const Checkout = () => {

  const stripe = useStripe()
  const elements = useElements()

  const [cartInfo, setCartInfo] = useState([])
  const [userIp, setUserIp] = useState(null)
  const [upSellProductsList, setUpSellProductsList] = useState([])
  const [relatedProductsList, setRelatedProductsList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const [userName, setUserName] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [targetUrl, setTargetUrl] = useState(null)
  const [checkoutFormStep, setCheckoutFormStep] = useState(1)
  const [isCouponValid, setIsCouponValid] = useState(false)
  const [validatingCouponError, setValidatingCouponError] = useState(null)
  const [coverInfo, setCoverInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [stripeErrorMessage, setStripeErrorMessage] = useState("")
  const [showCampaingSummary, setShowCampaingSummary] = useState(false)
  const [cookies, setCookie] = useCookies()
  const [paymentError, setPaymentError] = useState(null)
  const [cardInput, setCardInput] = useState(null)
  const [paymentId, setPaymentId] = useState(null)
  const [cardObject, setCardObject] = useState(null)
  const [paymentObject, setPaymentObject] = useState(null)


  const sendNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      top: 100
    })
  }

  const getCartInfo = (cartId, coupon) => {
    handleCartInfo(cartId, (error, cartInfo) => {
      if (error) {
        navigate(`/`)
        return sendNotification("error", "Something went wrong!", error)
      }
      if (cartInfo && cartInfo.cartItems.length > 0) {
        setCartInfo(cartInfo)
        setSelectedProduct(cartInfo.cartItems[0])

        const cookieEmail = cookies["customer.email"]
          ? cookies["customer.email"]
          : ""
        const cookieUsername = cookies["customer.username"]
          ? cookies["customer.username"]
          : ""
        const cookieUrl = cookies["customer.url"] ? cookies["customer.url"] : ""

        setUserEmail(cartInfo.userEmail ? cartInfo.userEmail : cookieEmail)
        setUserName(cartInfo.userName ? cartInfo.userName : cookieUsername)
        setTargetUrl(cartInfo.url ? cartInfo.url : cookieUrl)

        if (coupon) {
          validateCoupon(cartInfo.cartId, getQueryParam("cd", location))
        }
      }
    })
  }

  const createNewCartAndAddItem = (itemReference, coupon) => {
    handleCreateCart(itemReference, (error, cartInfo) => {
      if (error) {
        return navigate(`/`)
      }
      document.cookie = `cart_id=${cartInfo.cartId}; path=/`
      getCartInfo(cartInfo.cartId, coupon)
    })
  }

  const validateCoupon = (cartId, coupon) => {
    setValidatingCouponError(null)
    handleValidateCoupon(cartId, coupon, userIp, error => {
      if (error) {
        return setValidatingCouponError(`Invalid coupon: ${coupon}`)
      }
      setIsCouponValid(true)
      getCartInfo(cartId)
    })
  }

  const getRelatedProductsList = cartId => {
    handleGetRelatedProductsList(cartId, (error, productsList) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      setRelatedProductsList(productsList)
    })
  }

  const changeSelectedProduct = newProductSku => {
    handleReplaceItemFromCart(
      cartInfo.cartId,
      selectedProduct.sku,
      newProductSku,
      error => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        getCartInfo(cartInfo.cartId)
      }
    )
  }

  const handleUpdateCartWithUserInfo = () => {
    if (userEmail === "") {
      return
    }

    if (userEmail && userName && targetUrl) {
      handleUpdateCart(
        cartInfo.cartId,
        userEmail,
        userName,
        targetUrl,
        error => {
          if (error) {
            return sendNotification("error", "Something went wrong!", error)
          }
          getCartInfo(cartInfo.cartId)
          getRelatedProductsList(cartInfo.cartId)
          setCheckoutFormStep(2)
        }
      )
    }
  }

  const addItemToCart = productSelected => {
    if (
      cartInfo.cartItems &&
      cartInfo.cartItems.some(
        item => item.subCategory === productSelected.subCategory
      )
    ) {
      return deleteItemFromCartAndAddNewOne(
        cartInfo.cartItems.find(
          item => item.subCategory === productSelected.subCategory
        ),
        productSelected
      )
    }
    handleAddItemsToCart(cartInfo.cartId, [productSelected.sku], error => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      getCartInfo(cartInfo.cartId)
    })
  }

  const deleteItemFromCartAndAddNewOne = (oldProduct, newProduct) => {
    handleDeleteItemFromCart(cartInfo.cartId, oldProduct.id, error => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      handleAddItemsToCart(cartInfo.cartId, [newProduct.sku], error => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        getCartInfo(cartInfo.cartId)
      })
    })
  }

  const deleteItemFromCart = item => {
    if (item && item.id) {
      handleDeleteItemFromCart(cartInfo.cartId, item.id, error => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        getCartInfo(cartInfo.cartId)
      })
    }
  }

  const getUserIp = () => {
    handleUserIp((error, userIp) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      setUserIp(userIp)
    })
  }

  const createNewOrder = async (method) => {
    if (!method || method === "") {
      method = "card"
    }
    setLoading(true)
    const utmSource = document.cookie.replace(
      /(?:(?:^|.*;\s*)document_referrer\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
    const utmMedium = getQueryParam("utm_medium", location)

    const cardElement = elements.getElement(CardNumberElement)
    const res = await stripe.createToken(cardElement)

    if (res.error) {
      setLoading(false)
      if (res.error.message) {
        setStripeErrorMessage(res.error.message)
      } else {
        setStripeErrorMessage("an error has occurred, try later")
      }
      return
    }

    let ip

    if (res) {
      ip = res.token.client_ip
    } else {
      ip = userIp
    }

    try {
      const orderRes = await handleCreateNewOrder(
        method,
        cartInfo.cartId,
        ip,
        utmSource,
        utmMedium
      )
      stripePayment(orderRes.orderId, res.token)

    } catch (error) {
      setLoading(false)
      setStripeErrorMessage("something went wrong!")
    }

  }

  const stripePayment = async (orderId, paymentMethod) => {

    try {
      const response = await handleCreateNewTransaction(
        orderId,
        paymentMethod.id,
        paymentMethod.card.brand,
        paymentMethod.card.funding,
        paymentMethod.card.country,
        paymentMethod.card.last4
      )
      confirmStripePayment(response.info.reference, response.info.transaction_id, paymentMethod)
    } catch (error) {
      setLoading(false)
      setStripeErrorMessage("something went wrong!")
    }


  }

  const confirmStripePayment = async (reference, transactionId, paymentMethod) => {
    console.log(reference)
    const cardElement = elements.getElement(CardNumberElement)
    const stripeResponse = await stripe.confirmCardPayment(
      reference,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userName,
            email: userEmail
          }
        }
      }
    )

    if (stripeResponse.error) {
      setLoading(false)
      setStripeErrorMessage(stripeResponse.error.message)
      return
    }

    try {
      const result = await handleConfirmPayment(
        transactionId,
        stripeResponse.paymentIntent.id,
        stripeResponse.paymentIntent.status,
        JSON.stringify(stripeResponse)
      )
      if (result.data.status === "success") {
        window.location.href = result.data.redirect
      } else {
        setLoading(false)
        setStripeErrorMessage("we cannot process your payment")
      }
    } catch (error) {
      setLoading(false)
      setStripeErrorMessage("we cannot process your payment")
      return
    }
  }

  useEffect(() => {
    if (cartInfo && cartInfo.cartId && checkoutFormStep === 1) {
      handleGetUpSellProductsList(cartInfo.cartId, (error, productsList) => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        setUpSellProductsList(productsList)
      })
    }
  }, [cartInfo])

  useEffect(() => {
    const cardIdFromCookie = handleGetCartIdFromCookie()
    const cardIdFromUrl = getQueryParam("token", location)
    const coupon = getQueryParam("cd", location)
    const itemReference = getQueryParam("ref", location)
    const paymentErr = getQueryParam("paymentError", location)

    if (paymentErr) {
      setPaymentError("Authorization failed")
    }

    // getUserIp()

    if (cardIdFromUrl) {
      const rgx = new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)*")
      if (cardIdFromUrl.match(rgx)) {
        document.cookie = `cart_id=${cardIdFromUrl}; path=/`
        getCartInfo(cardIdFromUrl, coupon)
        return
      }
    }
    if (cardIdFromCookie) {
      getCartInfo(cardIdFromCookie, coupon)
      return
    }
    if (itemReference) {
      createNewCartAndAddItem(itemReference, coupon)
      return
    }

    navigate(`/`)
  }, [])

  return (
    <Layout showFooter={false}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div
        className="py-5 border-gray-200 fixed w-full bg-white z-50 flex justify-between items-center"
        style={{ borderBottom: "1px solid #e6e7eb" }}
      >
        <div className="pl-2 sm:pl-10 w-12/12 sm:w-9/12">
          <RealBoostLogo />
        </div>
        <div
          className="hidden sm:inline w-3/12 text-xl text-center color-home"
          style={{ textAlign: "left", marginLeft: "75px", fontSize: "17px" }}
        >
          Campaign Summary
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #e6e7eb",
          borderTop: "1px solid #e6e7eb"
        }}
        className="sm:hidden p-2 mt-20 fixed w-full bg-white z-50 flex justify-between items-center color-home"
        onClick={() => setShowCampaingSummary(prevState => !prevState)}
      >
        <div
          className="w-12/12 text-lg text-center py-2"
          style={{ marginLeft: "20px" }}
        >
          Campaign Summary
        </div>
        <div className="py-2">
          <CaretDownOutlined />
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <div className="w-12/12 sm:w-9/12 mt-40 sm:mt-28">
            <center>
              {checkoutFormStep === 1 && (
                <div className="max-w-3xl px-5">
                  <div className="px-5 mb-5 flex items-center justify-between">
                    <div className="flex flex-col items-center mx-2 rounded-full">
                      <div
                        className="h-9 w-9 flex justify-center items-center mx-2 rounded-full mb-2"
                        style={{ backgroundColor: "#3ebb55", color: "white" }}
                      >
                        1
                      </div>
                      <div className="text-gray-400">Information</div>
                    </div>
                    <div
                      className="w-3/5 sm:w-4/5 mb-6"
                      style={{ border: "1px solid #e5e7eb" }}
                    ></div>
                    <div className="flex flex-col items-center mx-2 rounded-full">
                      <div
                        className="h-9 w-9 flex justify-center items-center mx-3 rounded-full border-2 text-gray-400 mb-2">
                        2
                      </div>
                      <div className="text-gray-400 whitespace-nowrap">
                        Campaign
                      </div>
                    </div>
                  </div>
                  <UserInfo
                    selectedProduct={selectedProduct}
                    changeSelectedProduct={changeSelectedProduct}
                    upSellProductsList={upSellProductsList}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    userName={userName}
                    setUserName={setUserName}
                    targetUrl={targetUrl}
                    setTargetUrl={setTargetUrl}
                    handleUpdateCartWithUserInfo={handleUpdateCartWithUserInfo}
                    isCouponValid={isCouponValid}
                    validatingCouponError={validatingCouponError}
                    coverInfo={coverInfo}
                    setCoverInfo={setCoverInfo}
                  />
                </div>
              )}

              {checkoutFormStep === 2 && (
                <div className="max-w-3xl px-5">
                  <div className="px-5 mb-5 flex items-center justify-between">
                    <div className="flex flex-col items-center mx-2 rounded-full">
                      <div className="h-9 w-9 flex justify-center items-center m-2 rounded-full border-2 text-gray-400">
                        1
                      </div>
                      <div className="text-gray-400">Information</div>
                    </div>
                    <div
                      className="w-3/5 sm:w-4/5 mb-6"
                      style={{ border: "1px solid rgb(62, 187, 85)" }}
                    ></div>
                    <div className="flex flex-col items-center mx-2 rounded-full">
                      <div
                        className="h-9 w-9 flex justify-center items-center m-2 rounded-full"
                        style={{ backgroundColor: "#3ebb55", color: "white" }}
                      >
                        2
                      </div>
                      <div className="text-gray-400 whitespace-nowrap">
                        Campaign
                      </div>
                    </div>
                  </div>
                  <CheckoutInfo
                    cartInfo={cartInfo}
                    relatedProductsList={relatedProductsList}
                    addItemToCart={addItemToCart}
                    deleteItemFromCart={deleteItemFromCart}
                    createNewOrder={createNewOrder}
                    userEmail={userEmail}
                    userName={userName}
                    targetUrl={targetUrl}
                    setCheckoutFormStep={setCheckoutFormStep}
                    loading={loading}
                    stripeErrorMessage={stripeErrorMessage}
                    setStripeErrorMessage={setStripeErrorMessage}
                    coverInfo={coverInfo}
                    userIp={userIp}
                    utmSource={document.cookie.replace(
                      /(?:(?:^|.*;\s*)document_referrer\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    )}
                    utmMedium={getQueryParam("utm_medium", location)}
                    paymentError={paymentError}
                    setCardObject={setCardObject}
                    setPaymentObject={setPaymentObject}
                  />
                </div>
              )}
            </center>
            <div style={{ marginBottom: "20px" }}>
              <center>
                <img
                  src={TrustIcons}
                  className="mt-4 w-80"
                  alt="icons of trust"
                />
              </center>
            </div>
            <div
              className="bg-gray border-t-2 mt-10 border-gray-200"
              style={{ borderTopWidth: "1px", display: "none" }}
            >
              <Testimonials
                categoryName="home"
                testimonialsList={[
                  {
                    name: "James Smith",
                    review: 5,
                    userImage: "user5",
                    comment:
                      "“Fastest service on the market tbh. It’s my third time with them”."
                  },
                  {
                    name: "Michael Wells",
                    review: 5,
                    userImage: "user20",
                    comment:
                      "“Simply great, in just a few days I increased the plays for my new EP considerably. I would definitely repeat”."
                  },
                  {
                    name: "Charlotte Miller",
                    review: 4,
                    userImage: "user14",
                    comment:
                      "“Many of my posts have gained loads of likes on their own thanks to the initial push from your service”."
                  }
                ]}
              />
            </div>
            <div
              className="p-5 text-center border-t-2 border-gray-200 bg-white"
              style={{ borderTopWidth: "1px" }}
            >
              ©2024 Boostik. All rights reserved |{" "}
              <Link to="/terms-and-conditions">Terms and conditions</Link>
              {" | "}
              <Link to="/privacy-policy">Privacy policy</Link>
            </div>
          </div>
          {/* Campaing Section */}
          <div
            className={`${
              showCampaingSummary ? "inline" : "hidden"
            } mt-36 pb-5 fixed w-full bg-white sm:relative sm:inline sm:w-3/12 sm:mt-20 sm:inset-y-0 sm:right-0`}
            style={{
              boxShadow: "0 2px 19px 4px rgb(0 0 0 / 4%) ",
              height: "100%"
            }}
          >
            <div
              className="p-5 h-full shadow-lg sm:w-3/12 sm:fixed"
              style={{ background: "white" }}
            >
              <div className="mb-4">
                {cartInfo &&
                  cartInfo.cartItems &&
                  cartInfo.cartItems.map(item => (
                    <div
                      className="flex justify-between items-center text-md mb-2"
                      key={item.id}
                    >
                      <div className="flex items-center">
                        <img src={item.image} className="w-12 mr-2" />
                        <span className="capitalize">
                          <b>
                            {item.times} {item.subCategory}
                          </b>
                        </span>
                      </div>
                      <div className="font-semibold">
                        {currencyToSymbol(item.currency)}
                        {item.amount}
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className="border-t-2 border-b-2"
                style={{ borderBottomWidth: "1px", borderTopWidth: "1px" }}
              >
                <div className="flex justify-between text-sm mt-4">
                  <div>SubTotal:</div>
                  <div className="font-semibold">
                    {cartInfo && cartInfo.currency
                      ? currencyToSymbol(cartInfo.currency)
                      : ""}
                    {cartInfo.subTotal}
                  </div>
                </div>

                {cartInfo && cartInfo.totalDiscount > 0 && (
                  <div className="flex justify-between text-sm mt-4">
                    <div>Discount:</div>
                    <div className="font-semibold">
                      {currencyToSymbol(cartInfo.currency)}
                      {cartInfo.totalDiscount}
                    </div>
                  </div>
                )}

                <div className="flex justify-between text-md mt-4 mb-4">
                  <div>Tax</div>
                  <div className="font-semibold">Included</div>
                </div>
              </div>
              <div className="flex justify-between text-lg  mt-4">
                <div>Total</div>
                <div className="font-semibold">
                  {cartInfo && cartInfo.currency
                    ? currencyToSymbol(cartInfo.currency)
                    : ""}
                  {cartInfo && cartInfo.totalAmount ? cartInfo.totalAmount : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Checkout
