import "regenerator-runtime/runtime"
import { updateCart } from "../domain/datasources/carts.remote"

export const handleUpdateCart = (cartId, email, name, url, callback) => {
  async function asyncCall() {
    const response = await updateCart(cartId, email, name, url)
    if (response.state === "success") {
      callback(null, response.message)
    }
  }
  return asyncCall()
}
