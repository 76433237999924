import "regenerator-runtime/runtime"
import { getUserIp } from "../domain/datasources/account.remote"

export const handleUserIp = callback => {
  async function asyncCall() {
    const response = await getUserIp()
    if (response.state === "success") {
      callback(null, response.userIp)
    }
  }
  return asyncCall()
}
