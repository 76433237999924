import "regenerator-runtime/runtime"
import { createTransaction } from "../domain/datasources/orders.remote"

export const handleCreateNewTransaction = async (
  orderId,
  paymentId,
  cardBrand,
  cardFunding,
  cardCountry,
  cardLast4,
  callback
) => {

    return await createTransaction(
      orderId,
      paymentId,
      cardBrand,
      cardFunding,
      cardCountry,
      cardLast4
    )


}
