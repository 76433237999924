import "regenerator-runtime/runtime"
import { gerRelatedProdutsList } from "../domain/datasources/products.remote"

export const handleGetRelatedProductsList = (cardId, callback) => {
  async function asyncCall() {
    const response = await gerRelatedProdutsList(cardId)
    if (response.state === "success") {
      callback(null, response.productsList)
    }
  }
  return asyncCall()
}
