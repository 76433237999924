import "regenerator-runtime/runtime"
import { deleteItemFromCart } from "../domain/datasources/carts.remote"

export const handleDeleteItemFromCart = (cartId, itemReference, callback) => {
  async function asyncCall() {
    const response = await deleteItemFromCart(cartId, itemReference)
    if (response.state === "success") {
      callback(null, response.message)
    }
  }
  return asyncCall()
}