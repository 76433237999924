import "regenerator-runtime/runtime"
import { confirmPayment } from "../domain/datasources/orders.remote"

export const handleConfirmPayment = async (
  transactionId,
  paymentIntentId,
  paymentStatus,
  confirmedCardResult
) => {
  return await confirmPayment(
    transactionId,
    paymentIntentId,
    paymentStatus,
    confirmedCardResult
  )
}
